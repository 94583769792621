<div
  class="opening"
  *ngIf="
    openingFormatted?.seasons?.length ||
    this.opening?.temporary_closed ||
    this.opening?.planned_reopening ||
    this.opening?.inactive_reason !== null
  "
>
  <!-- Loop through seasons -->
  <div *ngFor="let season of openingFormatted.seasons" class="season">
    <div *ngIf="!season.frequented" class="opening__title">{{ 'label.actual-open-hours' | translate }}:</div>
    <div *ngIf="season.frequented" class="opening__title">{{ 'label.seasonal-open-hours' | translate }}:</div>

    <div *ngIf="season.from && season.to" class="season__range">
      {{ season.from + ' - ' + season.to }}
    </div>

    <!-- Display hours -->
    <div class="opening_days" *ngIf="season.hours?.length && !season.nonstop">
      <div class="opening__day" *ngFor="let day of season.hours">
        <span *ngIf="day?.day_of_week">{{ 'label.' + day.day_of_week | translate }}: </span>
        <span *ngIf="!day?.open && !day?.closed">{{ 'label.closed' | translate }}</span>
        <span *ngIf="day?.open">{{ formatTime(day.open) }}</span>
        <span *ngIf="day?.open && day?.closed"> - </span>
        <span *ngIf="day?.closed">{{ formatTime(day.closed) }}</span>
      </div>
    </div>

    <!-- Nonstop -->
    <div class="opening_days" *ngIf="season.hours?.length === 0 && season.nonstop">
      <div class="opening__day">
        <span>{{ 'label.alldays' | translate }}:&nbsp;</span>
        <span>00:00 - 24:00</span>
      </div>
    </div>
  </div>

  <!-- Temporary closed -->
  <div class="opening__comment" *ngIf="this.opening?.temporary_closed">
    {{ 'label.temporary-closed' | translate: { date: dateLabel(this.opening.planned_reopening) } }}
  </div>

  <!-- Inactive reason -->
  <div class="opening__comment" *ngIf="this.opening?.inactive_reason !== null">
    {{ this.opening.inactive_reason }}
  </div>
</div>
