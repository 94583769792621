import { Component, Input, OnInit } from '@angular/core';
import { DateLabelService } from '../../services/date-label.service';
import { TrendencyUtilsService } from '../../../../../trendency/utils';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss'],
})
export class OpeningHoursComponent implements OnInit {
  @Input() opening: any;

  openingState: {
    from: string;
    to: string;
  };
  openingFormatted: {
    seasons: Array<{
      hours: Array<any>;
      nonstop: boolean;
      from: string;
      to: string;
    }>;
  };

  constructor(private readonly dateLabelService: DateLabelService, private readonly utilsService: TrendencyUtilsService) {}

  ngOnInit(): void {
    this.openingFormatted = this.getOpeningHours();
  }

  formatTime(time: string): string {
    return this.dateLabelService.formatTime(time);
  }

  getOpeningHours(): { seasons: Array<any>; nonstop: boolean } {
    if (
      !this.opening?.length ||
      !this.opening[0]?.openingSeason?.length
    ) {
      return undefined;
    }

    const openingSeasons = this.opening[0].openingSeason.map((season) => {
      if (!season.openingHours?.length) {
        return null;
      }

      const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

      const openingHours = season.openingHours.length === 1 &&
        this.utilsService.sliceStringAfterCharacter(season.openingHours[0].day_of_week, '#') === 'allday'
        ? []
        : days.map((day) => {
            const dayObj = season.openingHours.find((item) => {
              item.day_of_week = this.utilsService.sliceStringAfterCharacter(item.day_of_week, '#');
              return item?.day_of_week === day;
            });

            return dayObj?.day_of_week ? dayObj : { day_of_week: day };
          });

      let from = null;
      let to = null;

      if (season.frequented_period) {
        from = this.utilsService.sliceStringAfterCharacter(season.from_monthAndDay, '#');
        to = this.utilsService.sliceStringAfterCharacter(season.to_monthAndDay, '#');
      }

      return {
        frequented: season.frequented_period,
        from: from ? this.dateLabelService.formatStringLocalDateString(from) : from,
        to: to ? this.dateLabelService.formatStringLocalDateString(to) : to,
        hours: openingHours,
        nonstop: openingHours.length === 0,
      };
    });

    // Filter out null values and return seasons
    return {
      seasons: openingSeasons.filter(Boolean),
      nonstop: openingSeasons.some((season) => season?.nonstop),
    };
  }

  getDateWithoutYear(str: string): string {
    if (!str) {
      return undefined;
    }
    return str.slice(5, str.length);
  }

  isFromJan1ToDec31(date1: string, date2: string): boolean {
    return date1 === 'january1' && date2 === 'december31';
  }

  dateLabel(date1: string, date2?: string): string {
    if (!date2) {
      return this.dateLabelService.createLabelSingle(date1);
    }
    return this.dateLabelService.createLabel(date1, date2);
  }
}
