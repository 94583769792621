<div class="popup">
  <a
    [routerLink]="config?.data?.link"
    [style.cursor]="config.data.link ? 'pointer' : 'auto'"
    *ngIf="config?.data?.link"
    target="_blank"
  ></a>
  <div
    *ngIf="config?.data?.thumbnail"
    [ngStyle]="{ 'background-image': 'url(' + config.data.thumbnail + ')' } | ssrEmptyString"
    class="image"
  ></div>
  <div
    *ngIf="!config?.data?.thumbnail && config?.data?.iconImage"
    [ngClass]="{ 'hasExternalIcon': config?.data?.hasExternalIcon }"
    [ngStyle]="{ 'background-image': 'url(' + config.data.iconImage + ')' } | ssrEmptyString"
    class="image icon"
  ></div>
  <div class="popup__bottom" *ngIf="config?.data?.category || config?.data?.title || config?.data?.opening">
    <span class="popup__category" *ngIf="config?.data?.category">{{ config.data.category }}</span>
    <span class="popup__title" *ngIf="config?.data?.title">{{ config.data.title }}</span>
    <app-opening-hours [opening]="config?.data?.opening" *ngIf="config?.data?.opening"></app-opening-hours>
  </div>
</div>
